import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const Bodymake = () => (
  <Container>
    <Seo
      title="ボディメイク"
      description="「体を鍛える」ことはメンズ美容において見逃せないポイントです。ここでは誰でも簡単にできるトレーニング方法やおすすめのプロテイン・サプリメントなどを紹介しています。"
    />
    <Category Title="Bodymake" SubTitle="ボディメイク" theCategory="bodymake" />
  </Container>
)

export default Bodymake
